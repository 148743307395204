import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../../components/AsyncComponent';

const EyeglassLensTypeWrap = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/eyeglassLensType/EyeglassLensTypeWrap')
)
const EyeglassLensMaterialWrap = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/eyeglassLensMaterial/EyeglassLensMaterialWrap')
)
const EyeglassLensFrameWrap = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/eyeglassLensFrame/EyeglassLensFrameWrap')
)
const EyeglassAddOnWrap = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/eyeglassAddOn/EyeglassAddOnWrap')
)
const MiscItemWrap = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/miscItems/MiscItemWrap')
)
const ContactLensWrap = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/contactLens/ContactLensWrap')
)
const ContactLensCleaningSolutionWrap = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/contactLensCleaningSolution/ContactLensCleaningSolutionWrap')
)

const OrderRoutes = (
  <Switch>
    <Route exact path="/settings/order-inventory/eyeglass" component={EyeglassLensTypeWrap} />
    <Route exact path="/settings/order-inventory/eyeglass/lens-material" component={EyeglassLensMaterialWrap} />
    <Route exact path="/settings/order-inventory/eyeglass/lens-frame" component={EyeglassLensFrameWrap} />
    <Route exact path="/settings/order-inventory/eyeglass/add-ons" component={EyeglassAddOnWrap} />
    <Route exact path="/settings/order-inventory/misc" component={MiscItemWrap} />
    <Redirect exact from="/settings/order-inventory" to="/settings/order-inventory/eyeglass" />
    {/* ContactLens */}
    <Route exact path="/settings/orders-inventory/contact-lens" component={ContactLensWrap} />
    <Route exact path="/settings/orders-inventory/contact-lens/cleaning-solution" component={ContactLensCleaningSolutionWrap} />
  </Switch>
);

export default OrderRoutes;